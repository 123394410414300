import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import axios from "axios";

const ConstructionPage = () => {
  const [file, setFile] = useState(null);
  const [restaurantName, setRestaurantName] = useState(""); // Added state for restaurantName
  const [email, setEmail] = useState(""); // Added state for email
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("pdf", file);
      formData.append("restaurantName", restaurantName); // Add this line
      formData.append("email", email); // Add this line

      axios
        .post("/api/v1/product/pdf-upload", formData)
        .then((response) => {
          // Handle the server response here if needed
          console.log("PDF uploaded successfully!", response);
         
          setSuccess(true);
        })
        .catch((error) => {
          // Handle the error if the upload fails
          console.error("Error uploading PDF:", error);
          setError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Layout title={"EE-MENU"} noHeader={true}>
      <>
        <div className="profile-container">
          <div className="profile-info">
            <h2 className="profile-name text-center">EE-MENU</h2>
            <p className="profile-name text-center">
              "Scan, dine, and delight – your menu at your fingertips with a QR
              code!"
            </p>
            <p className="profile-name text-center">
              <Link to="https://eenth.com" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "thin solid gray",
                    padding: "5px 15px",
                    borderRadius: 5,
                  }}
                >
                  Contact Us
                </button>
              </Link>
            </p>
          </div>
        </div>
        {success ? (
          <p style={{ margin: 25,color:"green" }}>Thanks for your interest. give us 24 hours to sort out the stuff. we will let you know the result through the email </p>
        ) : error?(
          <>
          <p style={{ margin: 25,color:"red" }}>Your details is not in correct format please change the details and try again </p>
         
          <button
                onClick={handleRefresh}
                style={{ border: "1px solid black", padding: "3px 7px",margin:25 }}
              >Try Again</button>
          </>
        ):(
          <div>
            <div style={{ margin: 25 }}>
              <p>Your restaurant/cafe name</p>
              <input
                type="text"
                value={restaurantName}
                onChange={(e) => setRestaurantName(e.target.value)}
              />
              <p>Your email</p>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p>Your Menu in pdf</p>
              <input type="file" onChange={handleFileChange} />
              <br />
              <br />
              <button
                onClick={handleUpload}
                style={{ border: "1px solid black", padding: "3px 7px" }}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export default ConstructionPage;
