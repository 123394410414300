import { NavLink, Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Import a spinner component or use your own
import Spinner from "./Spinner"; // Replace with the actual spinner component you want to use
import Layout from "../components/Layout/Layout";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";

const CalicutKitchensMenu = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Initial loading state
  const [toggleOn, setToggleOn] = useState(false);
  // FOR LOGIN START
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // This function will be called each time an image is loaded
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
    };

    // List of image sources
    const imageSources = [
      "../bb/1.jpg",
      "../bb/2.jpg",
      "../bb/3.jpg",
      "../bb/4.jpg",
      "../bb/5.jpg",
      "../bb/6.jpg",
      "../bb/7.jpg",
      "../bb/8.jpg",
      "../bb/9.jpg",
      "../bb/10.jpg",
      "../bb/11.jpg",
      "../bb/12.jpg",
      "../bb/13.jpg",
      "../bb/14.jpg",
      "../bb/15.jpg",
      "../bb/16.jpg",
      "../bb/17.jpg",
      "../bb/18.jpg",
      "../bb/19.jpg",
      "../bb/20.jpg",
      "../bb/21.jpg",
      "../bb/22.jpg",
      "../bb/23.jpg",
      "../bb/24.jpg",
      "../bb/25.jpg",
      "../bb/26.jpg",
      "../bb/27.jpg",
      "../bb/28.jpg",
      "../bb/29.jpg",
      "../bb/30.jpg",
      "../bb/31.jpg",
      "../bb/32.jpg",
      "../bb/33.jpg",
      "../bb/34.jpg",
      "../bb/35.jpg",
      "../bb/36.jpg",
      "../bb/37.jpg",
      "../bb/38.jpg",
      "../bb/39.jpg",
      "../bb/40.jpg",
      "../bb/41.jpg",
      "../bb/42.jpg",
      "../bb/43.jpg",
      "../bb/44.jpg",
      "../bb/45.jpg",
      "../bb/46.jpg",
      "../bb/47.jpg",
      "../bb/48.jpg",
    ];

    // Load the first two images
    for (let i = 0; i < 2; i++) {
      const img = new Image();
      img.src = imageSources[i];
      img.onload = handleImageLoad;
    }
  }, []);

  useEffect(() => {
    // Check if the first two images are fully loaded
    if (imagesLoaded === 2) {
      // All required images are loaded, set loading state to false
      setIsLoading(false);
    }
  }, [imagesLoaded]);

  // Define the imageSources array here
  const imageSources = [
    "../bb/1.jpg",
    "../bb/2.jpg",
    "../bb/3.jpg",
    "../bb/4.jpg",
    "../bb/5.jpg",
    "../bb/6.jpg",
    "../bb/7.jpg",
    "../bb/8.jpg",
    "../bb/9.jpg",
    "../bb/10.jpg",
    "../bb/11.jpg",
    "../bb/12.jpg",
    "../bb/13.jpg",
    "../bb/14.jpg",
    "../bb/15.jpg",
    "../bb/16.jpg",
    "../bb/17.jpg",
    "../bb/18.jpg",
    "../bb/19.jpg",
    "../bb/20.jpg",
    "../bb/21.jpg",
    "../bb/22.jpg",
    "../bb/23.jpg",
    "../bb/24.jpg",
    "../bb/25.jpg",
    "../bb/26.jpg",
    "../bb/27.jpg",
    "../bb/28.jpg",
    "../bb/29.jpg",
    "../bb/30.jpg",
    "../bb/31.jpg",
    "../bb/32.jpg",
    "../bb/33.jpg",
    "../bb/34.jpg",
    "../bb/35.jpg",
    "../bb/36.jpg",
    "../bb/37.jpg",
    "../bb/38.jpg",
    "../bb/39.jpg",
    "../bb/40.jpg",
    "../bb/41.jpg",
    "../bb/42.jpg",
    "../bb/43.jpg",
    "../bb/44.jpg",
    "../bb/45.jpg",
    "../bb/46.jpg",
    "../bb/47.jpg",
    "../bb/48.jpg",
  ];

  const scrollToDiv = (n) => {
    const element = document.getElementById(n); // Replace 'myDiv' with the actual ID of your target div
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // const currentScrollPosition = window.scrollY;
    // const scrollAmount = currentScrollPosition - 100;
    // window.scrollTo(0, scrollAmount);

  };

  const handleToggle = () => {
    setToggleOn(!toggleOn);
    // console.log("now",toggleOn)
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click event occurred outside the navbar
      // if (!event.target.closest(".navbar")) {
        // Close the navbar menu
        const navbarCollapse = document.querySelector(".navbar-collapse");
        if (navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
        }
      // }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Layout  title={"Calicut Kitchens Bengaluru"} noHeader={true} >
      <nav
        className="navbar navbar-expand-lg fixed-top"
        // ref={menuButtonRef}
        style={{ backgroundColor: "white" }}
      >
        <div className="container-fluid">
          <Link to="/calicut-kitchens-bengaluru" className="navbar-brand">
            <img
              src="../../images/calicut-kitchens-logo.png"
              style={{ height: "80px" }}
              alt="logo"
            />
          </Link>
          {/* <button
            className="navbar-toggler position-relative"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="big"
          >
            {toggleOn ? (
              <>
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </>
            ) : (
              <>
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </>
            )}
            <span
              className="position-absolute"
              style={{ top: -7, right: 0 }}
            ></span>
          </button> */}

          <div
            className="collapse navbar-collapse"
            id="navbarTogglerDemo02"
            // ref={menuRef}
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(0);
                  }}
                >
                  Lime
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(1);
                  }}
                >
                  Fresh Juice
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(2);
                  }}
                >
                  Fruit Shakes
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(4);
                  }}
                >
                  Ice cream Shakes
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(5);
                  }}
                >
                  Ice cream Shakes with Scoup
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(6);
                  }}
                >
                  Mojito
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(7);
                  }}
                >
                  Fruit Salad
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(8);
                  }}
                >
                  Falooda
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(9);
                  }}
                >
                  Sundae
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(10);
                  }}
                >
                  Sizzler's
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(11);
                  }}
                >
                  Calicut Special
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item rounded-2"
                  onClick={() => {
                    scrollToDiv(12);
                  }}
                >
                  Smoothie
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div style={{marginTop: 100}}>
        {/* Conditionally render the spinner while loading */}
        {isLoading ? (
          <Spinner /> // Replace with your spinner component
        ) : (
          // Once loading is complete, render your images
          <>
            {imageSources.map((src, index) => (
              <section id={index} className="pt-2">
                <img src={src} style={{ width: "100%", height: "auto" }} />
              </section>
            ))}
        
          </>
        )}
      </div>
    </Layout>
  );
};

export default CalicutKitchensMenu;
