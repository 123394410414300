import React from "react";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="text-center pb-4 pt-4"
        style={{ backgroundColor: "#F8F8FE" }}
      >
        <div>
          <p
            style={{
              
              fontSize: 12,
              marginBottom: 0,
              color: "black",
            }}
            className="float-right"
          >
            Developed by{" "}
            <Link to="https://eenth.com" style={{ textDecoration: "none",cursor: "pointer", }}>
              <b>EENTH</b>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
