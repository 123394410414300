import { Spinner } from "@chakra-ui/react";
import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CalicutKitchensHome = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Preload the background image
  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = "b/0.jpg";
    backgroundImage.onload = handleImageLoad;
  }, []);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <Layout
      title={"Calicut Kitchens Bengaluru"}
      noHeader={true}
      noFooter={true}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          style={{
            background: `url("b/0.jpg") no-repeat center center fixed`,
            backgroundColor: "#35574e",
            backgroundSize: "cover",
            width: "100%",
            height:
              "100vh" /* Set the background container to cover the entire viewport */,
            position:
              "relative" /* Ensure child elements stay within this container */,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: "100%",
              padding: "20px" /* Add padding to center content */,
              marginTop: 80,
            }}
          >
            <p className="text-center" style={{ margin: "auto" }}>
              <Link
                to="/calicut-kitchens-bengaluru/menu"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="text-center"
                  style={{
                    border: "thin solid #e32c82",
                    backgroundColor: "#e32c82",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  OPEN MENU
                </button>
              </Link>
            </p>

            <p className="mt-5" style={{ color: "white" }}>
              If you found your meal enjoyable today, please feel free to place
              another order at your convenience.
            </p>
            <p className="text-center" style={{ margin: "auto" }}>
              <Link to="tel:+918088109880" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "thin solid #f0e013",
                    backgroundColor: "#f0e013",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "black",
                  }}
                >
                  CALL CALICUT KITCHENS
                </button>
              </Link>
            </p>
            <p className="text-center mt-3" style={{ margin: "auto" }}>
              <Link
                to="https://zoma.to/r/20818540"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="text-center"
                  style={{
                    border: "thin solid #cb202d",
                    backgroundColor: "#cb202d",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    margin: "0 15px",
                  }}
                >
                  ZOMATO
                </button>
              </Link>

              <Link
                to="https://www.swiggy.com/restaurants/calicut-kitchen-btm-bangalore-761282"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="text-center"
                  style={{
                    border: "thin solid #fc8019",
                    backgroundColor: "#fc8019",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    margin: "0 15px",
                  }}
                >
                  SWIGGY
                </button>
              </Link>
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CalicutKitchensHome;
