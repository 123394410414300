import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
// import { FiMenu } from "react-icons/fi";
import { useAuth } from "../../context/auth";
import { toast } from "react-hot-toast";
import { useCart } from "../../context/cart";
import { Badge } from "antd";
//import { useMediaQuery } from "react-responsive";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";

const Header = () => {
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const navigate = useNavigate();
  const [cookieMessage, setCookieMessage] = useState(false);
  const [betaMessage, setBetaMessage] = useState(false);
  //const isMobileView = useMediaQuery({ query: "(max-width: 991px)" });
  const [toggleOn, setToggleOn] = useState(false);

  // FOR LOGIN START
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  // FOR LOGIN END

  const handleToggle = () => {
    setToggleOn(!toggleOn);
    // console.log("now",toggleOn)
  };

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    // toast.success("Logout Successful");
  };

  function handlecookieMessage() {
    localStorage.setItem("cookieMsg", "viewed");
    console.log("Clicked");
    setCookieMessage(false);
  }

  function handleBetaMessage() {
    localStorage.setItem("betaMsg", "viewed");
    console.log("Clicked");
    setBetaMessage(false);
  }

  useEffect(() => {
    const cookieMsg = localStorage.getItem("cookieMsg");
    const betaMsg = localStorage.getItem("betaMsg");
    if (!cookieMsg) {
      setCookieMessage(true);
    } else {
      setCookieMessage(false);
    }
    if (!betaMsg) {
      setBetaMessage(true);
    } else {
      setBetaMessage(false);
    }
  }, []);

  const co = cart?.reduce(
    (accumulator, cart) => accumulator + cart.quantity,
    0
  );

  // const menuRef = useRef(null);
  // const menuButtonRef = useRef(null);
  // const buttonRef = useRef(null);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       menuRef.current &&
  //       !menuRef.current.contains(event.target) &&
  //       menuButtonRef.current &&
  //       !menuButtonRef.current.contains(event.target)
  //     ) {
  //       console.log("Outside");
  //       console.log("current",toggleOn)
  //       if (toggleOn===true) {
  //         buttonRef?.current?.click();
  //       }
  //       menuRef.current.classList.add("hidden");
  //     }
  //   };

  //   document.addEventListener("click", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click event occurred outside the navbar
      if (!event.target.closest(".navbar")) {
        // Close the navbar menu
        const navbarCollapse = document.querySelector(".navbar-collapse");
        if (navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
        }
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        // ref={menuButtonRef}
        style={{ backgroundColor: "white" }}
      >
        <div className="container-fluid">
          <Link to="/beta" className="navbar-brand">
            <img
              src="../../images/swapanzee-logo.jpg"
              style={{ height: "27px", marginLeft: "12px", marginTop: 10 }}
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler position-relative"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            // onClick={handleToggle}
            id="big"
            // ref={buttonRef}
          >
            {toggleOn ? (
              <>
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </>
            ) : (
              <>
                <span className="menu"></span>
                <span className="menu"></span>
                <span className="menu"></span>
              </>
            )}
            <span className="position-absolute" style={{ top: -7, right: 0 }}>
              {co != 0 ? (
                <Badge count={co} showZero color="#DD8A37"></Badge>
              ) : (
                ""
              )}
            </span>
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarTogglerDemo02"
            // ref={menuRef}
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <NavLink
                  to={`${auth?.user ? "/profile" : "/login"}`}
                  className="nav-link"
                >
                  Account
                </NavLink>
              </li> */}

              {/* ACCOUNT */}
              {/* {auth?.user ? (
                <li className="nav-item">
                  <NavLink to="/profile" className="nav-link">
                    Account
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate("/login", {
                        state: "/profile",
                      });
                    }}
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                  >
                    Account
                  </a>
                </li>
              )} */}

              <li className="nav-item">
                <NavLink to="/beta" className="nav-link">
                  How to Swapanzee
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/participation" className="nav-link">
                  Search
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/campaign-results" className="nav-link">
                  Categories
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink to="/cart" className="nav-link">
                  Cart <Badge count={co} showZero color="#DD8A37"></Badge>
                </NavLink>
              </li> */}

              {/* REFERRALS */}
              <li className="nav-item">
                <NavLink to="/referrals" className="nav-link">
                  Swapanzee Posts
                </NavLink>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Legal Documents
                </a>
                <ul className="dropdown-menu" style={{ border: "none" }}>
                  <li className="nav-item">
                    <NavLink
                      to="/terms"
                      className="dropdown-item"
                      style={{
                        fontSize: 14,
                        background: "none",
                        color: "#000000A6",
                      }}
                    >
                      Terms & Conditions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/eula"
                      className="dropdown-item"
                      style={{
                        fontSize: 14,
                        background: "none",
                        color: "#000000A6",
                      }}
                    >
                      EULA
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/policy"
                      className="dropdown-item"
                      style={{
                        fontSize: 14,
                        background: "none",
                        color: "#000000A6",
                      }}
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item" onClick={onOpen}>
                <NavLink className="nav-link">Login/Register</NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink to="https://tobtek.com" className="nav-link">
                  Powered by{" "}
                  <img
                    src="../../images/tobtek-logo.png"
                    style={{
                      width: 30,
                      height: "auto",
                      display: "inline-block",
                      paddingBottom: 5,
                    }}
                  />
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      {/* Beta message */}
      {/* {betaMessage && (
        <div
          className="text-center p-2"
          id="cookieMessage"
          style={{
            display: "block",
            bottom: 152,
            left: 0,
            zIndex: 601,
            backgroundColor: "white",
            position: "fixed",
            opacity: ".9",
            height: "7rem",
            width: "100%",
          }}
        >
          <p style={{ fontSize: ".8rem", marginBottom: 5 }}>
            Beta Testing: This platform is currently not available for public use.
          </p>
         
          <button
            className="btn btStandardFill pt-0"
            onClick={handleBetaMessage}
          >
            OK
          </button>
        </div>
      )} */}

      {/* cookie message */}
      {/* {cookieMessage && (
        <div
          className="text-center p-2"
          id="cookieMessage"
          style={{
            display: "block",
            bottom: 20,
            left: 0,
            zIndex: 601,
            backgroundColor: "white",
            position: "fixed",
            opacity: ".9",
            height: "7rem",
            width: "100%",
          }}
        >
          <p style={{ fontSize: ".8rem", marginBottom: 0 }}>
            Cookies help us improve your website experience.
          </p>
          <p style={{ fontSize: ".8rem", marginBottom: 0 }}>
            By using our website, you agree to our use of cookies.
          </p>
          <p style={{ fontSize: ".8rem", marginBottom: 5 }}>
            To find out more read our{" "}
            <Link
              to="/policy"
              style={{ textDecoration: "none", color: "#DD8A37" }}
            >
              Cookie Policy
            </Link>
            .
          </p>
          <button
            className="btn btStandardFill pt-0"
            onClick={handlecookieMessage}
          >
            Confirm
          </button>
        </div>
      )} */}

      <Button onClick={onOpen}>Open Modal</Button>
      <Button ml={4} ref={finalRef}>
        I'll receive focus on close
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent ml={10} mr={10}>
          <Link to="/beta" className="navbar-brand">
            <img
              src="../../images/swapanzee-logo.jpg"
              style={{ height: "20px", margin: "auto", marginTop: 30 }}
              alt="logo"
            />
          </Link>
          <ModalHeader>
            <p style={{ fontSize: 16 }}>
              <center>Login</center>
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel mb={0}>Email</FormLabel>
              <Input ref={initialRef} placeholder="Email" size="sm" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel mb={0}>Password</FormLabel>
              <Input placeholder="Password" size="sm" />
            </FormControl>

            <Checkbox colorScheme="red" defaultChecked mt={4}>
              Keep me Logged In
            </Checkbox>
          </ModalBody>

          <ModalFooter m={"auto"}>
            <Button colorScheme="blue" size="sm">
              Login
            </Button>
            {/* <Button onClick={onClose}>Cancel</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Header;
