import { Routes, Route, Navigate } from "react-router-dom";
import Pagenotfound from "./pages/Pagenotfound";
import "react-toastify/dist/ReactToastify.css";
import ConstructionPage from "./pages/ConstructionPage";
import React, { useEffect, useState } from "react";

import ScrollToTop from "./pages/ScrollToTop";
import Nandhini from "./pages/NandiniCafeMenu";
import { useParams } from "react-router-dom"; // Import useParams
import NandiniCafeHome from "./pages/NandiniCafeMooHome";
import NandiniCafeMenu from "./pages/NandiniCafeMenu";
import CalicutKitchensHome from "./pages/CalicutKitchensHome";
import CalicutKitchensMenu from "./pages/CalicutKitchensMenu";
import PDFViewer from "./pages/PDFViewer";
import KhairMenu from "./pages/KhairMenu";
import KhairHome from "./pages/KhairHome";
import NandiniCafeEdappalMenu from "./pages/NandiniCafeEdappalMenu";
import NandiniCafePerinthalmannaMenu from "./pages/NandiniCafePerinthalmannaMenu";
import NandiniCafePalakkadMenu from "./pages/NandiniCafePalakkadMenu";
import NandiniCafeGuruvayoorMenu from "./pages/NandiniCafeGuruvayoorMenu";
import NandiniCafeMooHome from "./pages/NandiniCafeMooHome";
import NandiniCafeCalicutHome from "./pages/NandiniCafeCalicutHome";

function App() {
  // const { id } = useParams(); // Get the route parameter 'id'
  // const [abc, setAbc] = useState("");

  // useEffect(() => {
  //   setAbc(`/nandini-cafe-moo-calicut-2/${id}`);
  //   console.log("param", id);
  // }, [id]); // Add 'id' as a dependency to the useEffect

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ConstructionPage />} />
        {/* NANDHINI CAFE */}
        <Route path="/4856" element={<Navigate replace to="/nandini-cafe-moo-calicut-2" />} />
        <Route path="/nandini-cafe-moo-calicut-2" element={<NandiniCafeCalicutHome />} />
        <Route path="/nandini-cafe-moo-calicut-2/menu" element={<NandiniCafeMenu />} />
        {/* 1246 4 branches */}
        <Route path="/1246" element={<Navigate replace to="/nandini-cafe-moo" />} /> 
        <Route path="/nandini-cafe-moo" element={<NandiniCafeMooHome />} />
        <Route path="/nandini-cafe-moo-edappal/menu" element={<NandiniCafeEdappalMenu />} />
        <Route path="/nandini-cafe-moo-perinthalmanna/menu" element={<NandiniCafePerinthalmannaMenu />} />
        <Route path="/nandini-cafe-moo-palakkad/menu" element={<NandiniCafePalakkadMenu />} />
        <Route path="/nandini-cafe-moo-guruvayoor/menu" element={<NandiniCafeGuruvayoorMenu />} />

        {/* CALIVUT KITCHEN */}
        <Route path="/7874" element={<Navigate replace to="/calicut-kitchens-bengaluru" />} />
        <Route path="/calicut-kitchens-bengaluru" element={<CalicutKitchensHome />} />
        <Route path="/calicut-kitchens-bengaluru/menu" element={<CalicutKitchensMenu />} />
        {/* <Route path="/calicut-kitchens-bengaluru/menu" element={<PDFViewer />} /> */}

        {/* AL KHAIR */}
        <Route path="/1693" element={<Navigate replace to="/khair-arabic-restaurant" />} />
        <Route path="/8396" element={<Navigate replace to="/khair-arabic-restaurant" />} />
        <Route path="/khair-arabic-restaurant" element={<KhairHome />} />
        <Route path="/khair-arabic-restaurant/menu" element={<KhairMenu />} />

         {/* SWEET SPOT */}
         {/* <Route path="/7261" element={<Navigate replace={true} href="https://sweetspot.ae" />} />
         <Route path="/7392" element={<Navigate replace to="https://sweetspot.ae" />} /> */}
               
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
