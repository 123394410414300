import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const KhairHome = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Preload the background image
  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = 'c/0.jpg';
    backgroundImage.onload = handleImageLoad;
  }, []);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <Layout title={"Khair Arabic Restaurant"} description={"9539986786"} noHeader={true} noFooter={true}>
      {isLoading ? (
        <Spinner /> // Show the spinner while loading
      ) : (
        <div
          style={{
            background: `url("c/0.jpg") no-repeat top center fixed`,
            backgroundColor: "black",
            backgroundSize: "cover",
            backgroundSize: "100%", // Set the background size to 100% to cover the container height
            width: "100%",
            height: "880px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: "100%",
              padding: "20px" /* Add padding to center content */,
              marginTop: 0,
            }}
          >
            <p className="text-center" style={{ margin: "auto" }}>
              <Link
                to="/khair-arabic-restaurant/menu"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="text-center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f0e013",
                    padding: "10px 40px",
                    borderRadius: "5px",
                    color: "black",
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  OPEN MENU
                </button>
              </Link>
            </p>

            {/* <p className="mt-5" style={{ color: "white" }}>
            If you found your meal enjoyable today, please feel free to place
            another order at your convenience.
          </p> */}
            <p className="text-center mt-5" style={{ margin: "auto" }}>
              <Link to="tel:+919539986786" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f0e013",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "black",
                    fontWeight: 600,
                    margin:"0 2px"
                  }}
                >
                  Call
                </button>
              </Link>
              <Link to="https://wa.me/+918129181617" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f0e013",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "black",
                    fontWeight: 600,
                    margin:"0 2px"
                  }}
                >
                  Whatsapp
                </button>
              </Link>
              <Link to="https://instagram.com/khair_arabicrestaurant?igshid=YTQwZjQ0NmI0OA==" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f0e013",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "black",
                    fontWeight: 600,
                    margin:"0 2px"
                  }}
                >
                 Instagram
                </button>
              </Link>
              <Link to="upi://pay?pa=paytmqr281005050101mx970olmkett@paytm&pn=Paytm%20Merchant&paytmqr=281005050101MX970OLMKETT" style={{ textDecoration: "none" }}>
                <button
                  className="text-center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f0e013",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "black",
                    fontWeight: 600,
                    margin:"0 2px"
                  }}
                >
                 Pay
                </button>
              </Link>
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default KhairHome;
