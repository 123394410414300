import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NandiniCafeMooHome = () => {
  return (
    <Layout title={"Nandini Cafe Moo"} noHeader={true} noFooter={true}>
      <div
        style={{
          background: `url("a/0.jpg") no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height:
            "120vh" /* Set the background container to cover the entire viewport */,
          position:
            "relative" /* Ensure child elements stay within this container */,
       
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "100%",
            padding: "20px" /* Add padding to center content */,
           
          }}
        >
         
          <p className="text-center" style={{ margin: "auto",marginTop:400 }}>
            <Link
              to="/nandini-cafe-moo-edappal/menu"
              style={{ textDecoration: "none" }}
            >
              <button
                className="text-center"
                style={{
                  border: "thin solid #e32c82",
                  backgroundColor: "#e32c82",
                  padding: "20px 20px",
                 width:"80%",
                  borderRadius: "5px",
                  color: "white",
                  fontSize:24
                }}
              >
                EDAPPAL
              </button>
            </Link>
          </p>
          <br />
          <p className="text-center" style={{ margin: "auto" }}>
            <Link
              to="/nandini-cafe-moo-perinthalmanna/menu"
              style={{ textDecoration: "none" }}
            >
              <button
                className="text-center"
                style={{
                  border: "thin solid #e32c82",
                  backgroundColor: "#e32c82",
                  padding: "20px 20px",
                 width:"80%",
                  borderRadius: "5px",
                  color: "white",
                  fontSize:24
                }}
              >
                PERINTHALMANNA
              </button>
            </Link>
          </p>
          <br />
          <p className="text-center" style={{ margin: "auto" }}>
            <Link
              to="/nandini-cafe-moo-palakkad/menu"
              style={{ textDecoration: "none" }}
            >
              <button
                className="text-center"
                style={{
                  border: "thin solid #e32c82",
                  backgroundColor: "#e32c82",
                  padding: "20px 20px",
                 width:"80%",
                  borderRadius: "5px",
                  color: "white",
                  fontSize:24
                }}
              >
                PALAKKAD
              </button>
            </Link>
          </p>
          <br />
          <p className="text-center" style={{ margin: "auto" }}>
            <Link
              to="/nandini-cafe-moo-guruvayoor/menu"
              style={{ textDecoration: "none" }}
            >
              <button
                className="text-center"
                style={{
                  border: "thin solid #e32c82",
                  backgroundColor: "#e32c82",
                  padding: "20px 20px",
                 width:"80%",
                  borderRadius: "5px",
                  color: "white",
                  fontSize:24
                }}
              >
                GURUVAYOOR
              </button>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NandiniCafeMooHome;
