import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

function PDFViewer() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const embedStyle = {
    width: "100vw",
    height: "100vh",
  }; 
   const iframeStyle = {
    width: "100%",
    height: "100vh",
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="pdf-container">
        <Document
          file="/images/ck-menu.pdf" // Replace with the path to your PDF file
          onLoadSuccess={onDocumentLoadSuccess}
          renderTextLayer={true}
          renderInteractiveForms={true}
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={window.innerWidth} // Set the width to the screen width
            />
          ))}
        </Document>

        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
      <div>
        <p>img</p>
        <img
          src="/images/ck-menu.pdf"
          type="application/pdf"
        //   style={embedStyle}
          width="100%"
        />
        <p>embed</p>
         <embed
          src="/images/ck-menu.pdf"
          type="application/pdf"
          style={embedStyle}
        />
        <p>iframe</p>
        <iframe   src="/images/ck-menu.pdf"
        //   type="application/pdf"
          style={iframeStyle}>
<p>Youyr browser not support</p>
        </iframe>

      </div>
    </>
  );
}

export default PDFViewer;
