import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";

// Import a spinner component or use your own
import Spinner from "./Spinner"; // Replace with the actual spinner component you want to use

const NandiniCafePerinthalmannaMenu = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Initial loading state

  useEffect(() => {
    // This function will be called each time an image is loaded
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
    };

    // List of image sources
    const imageSources = [
      "../aa/0.jpg",
      "../aa/1.jpg",
      "../aa/2.jpg",
      "../aa/3.jpg",
      "../aa/4.jpg",
      "../aa/5.jpg",
      "../aa/6.jpg",
      "../aa/7.jpg",
      "../aa/8.jpg",
      "../aa/9.jpg",
      "../aa/10.jpg",
      "../aa/11.jpg",
      "../aa/12.jpg",
      "../aa/13.jpg",
      "../aa/14.jpg",
      "../aa/15.jpg",
      "../aa/16.jpg",
      "../aa/17.jpg",
      "../aa/18.jpg",
      "../aa/perinthalmanna.jpg",
    ];

    // Load the first two images
    for (let i = 0; i < 2; i++) {
      const img = new Image();
      img.src = imageSources[i];
      img.onload = handleImageLoad;
    }
  }, []);

  useEffect(() => {
    // Check if the first two images are fully loaded
    if (imagesLoaded === 2) {
      // All required images are loaded, set loading state to false
      setIsLoading(false);
    }
  }, [imagesLoaded]);

  // Define the imageSources array here
  const imageSources = [
    "../aa/0.jpg",
    "../aa/1.jpg",
      "../aa/2.jpg",
      "../aa/3.jpg",
      "../aa/4.jpg",
      "../aa/5.jpg",
      "../aa/6.jpg",
      "../aa/7.jpg",
      "../aa/8.jpg",
      "../aa/9.jpg",
      "../aa/10.jpg",
      "../aa/11.jpg",
      "../aa/12.jpg",
      "../aa/13.jpg",
      "../aa/14.jpg",
      "../aa/15.jpg",
      "../aa/16.jpg",
      "../aa/17.jpg",
      "../aa/18.jpg",
      "../aa/perinthalmanna.jpg",
  ];

  const [divColor, setDivColor] = useState("20px");

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the distance from the bottom of the page
      const distanceFromBottom =
        document.documentElement.scrollHeight -
        window.scrollY -
        window.innerHeight;

      // Check if the distance is less than or equal to 50 pixels
      if (distanceFromBottom <= 65) {
        setDivColor("450px");
      } else {
        setDivColor("20px");
      }
    };

    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout title={"Nandini Cafe Moo Calicut 2"} noHeader={true}>
      <div>
        {/* Conditionally render the spinner while loading */}
        {isLoading ? (
          <Spinner /> // Replace with your spinner component
        ) : (
          // Once loading is complete, render your images
          imageSources.map((src, index) => (
            <img
              key={index}
              src={src}
              style={{ width: "100%", height: "auto" }}
            />
          ))
        )}
      </div>

      <div>
        <div
          className="social-button-call"
          style={{ bottom: divColor }}
        >
          <a href="tel:+919002610400" target="_blank">
            <img
              src="../images/call.png"
              alt="phone"
              style={{ width: 40, height: "auto" }}
            />
          </a>
        </div>
        <div
          className="social-button-whatsapp"
          style={{ bottom: divColor }}
        >
          <a href="tel:+919002612444" target="_blank">
            <img
              src="../images/call.png"
              alt="phone"
              style={{ width: 40, height: "auto" }}
            />
          </a>
        </div>
        <div
          className="social-button-facebook"
          style={{ bottom: divColor }}
        >
          <a
            href="https://wa.me/+919002610400"
            target="_blank"
          >
            <img
              src="../images/whatsapp.svg"
              alt="fb"
              style={{ width: 40, height: "auto" }}
            />
          </a>
        </div>
        <div className="social-button" style={{ bottom: divColor }}>
          <a
            href="https://www.instagram.com/nandinicafemoo_perinthalmanna?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
            target="_blank"
          >
            <img
              src="../images/instagram.svg"
              alt="Instagram"
              style={{ width: 40, height: "auto" }}
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default NandiniCafePerinthalmannaMenu;
