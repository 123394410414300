import Layout from "../components/Layout/Layout";
import React, { useState, useEffect } from "react";

// Import a spinner component or use your own
import Spinner from "./Spinner"; // Replace with the actual spinner component you want to use

const NandiniCafeMenu = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Initial loading state

  useEffect(() => {
    // This function will be called each time an image is loaded
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
    };

    // List of image sources
    const imageSources = [
      "../a/2.png",
      "../a/3.png",
      "../a/4.png",
      "../a/5.png",
      "../a/6.png",
      "../a/7.png",
      "../a/8.png",
      "../a/9.png",
      "../a/10.png",
      "../a/11.png",
      "../a/12.png",
      "../a/13.png",
      "../a/14.png",
      "../a/15.png",
      "../a/calicut.png",
    ];

    // Load the first two images
    for (let i = 0; i < 2; i++) {
      const img = new Image();
      img.src = imageSources[i];
      img.onload = handleImageLoad;
    }
  }, []);

  useEffect(() => {
    // Check if the first two images are fully loaded
    if (imagesLoaded === 2) {
      // All required images are loaded, set loading state to false
      setIsLoading(false);
    }
  }, [imagesLoaded]);

  // Define the imageSources array here
  const imageSources = [
    "../a/2.png",
    "../a/3.png",
    "../a/4.png",
    "../a/5.png",
    "../a/6.png",
    "../a/7.png",
    "../a/8.png",
    "../a/9.png",
    "../a/10.png",
    "../a/11.png",
    "../a/12.png",
    "../a/13.png",
    "../a/14.png",
    "../a/15.png",
    "../a/calicut.png",
  ];

  return (
    <Layout title={"Nandini Cafe Moo Calicut 2"} noHeader={true}>
      <div>
        {/* Conditionally render the spinner while loading */}
        {isLoading ? (
          <Spinner /> // Replace with your spinner component
        ) : (
          // Once loading is complete, render your images
          imageSources.map((src, index) => (
            <img
              key={index}
              src={src}
              style={{ width: "100%", height: "auto" }}
            />
          ))
        )}
      </div>
    </Layout>
  );
};

export default NandiniCafeMenu;
