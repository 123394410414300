import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import UnscrollableSection from "./UnScrollableSection";

const Layout = ({ children, title, description, keywords, noHeader,noFooter }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <title>{title}</title>

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="images/logo.png" />
      </Helmet>
      {noHeader ? "" : <Header />}
      {/* {noHeader ? "" : <UnscrollableSection />} */}
      <main
        style={{
          minHeight: "100vh",
         // height: "100vh",
          // backgroundColor: "#F8F8FE",
          paddingTop: 0,
          // paddingBottom: 28,
        }}
      >
        <Toaster />
        {children}
      </main>
      {noFooter ? "" : <Footer />}
    
    </div>
  );
};

Layout.defaultProps = {
  title: "EE-MENU",
  description:
    "Scan, dine, and delight – your menu at your fingertips with a QR code!",
  keywords: "menu,qr code,digital menu,restaurant,digital",
};

export default Layout;
